const types = {
  // CHECK_AUTHORIZATION: 'CHECK_AUTHORIZATION',
  CREATE_COURSE_REQUEST: 'CREATE_COURSE_REQUEST',
  CREATE_COURSE: 'CREATE_COURSE',
  DELETE_COURSE_REQUEST: 'DELETE_COURSE_REQUEST',
  DELETE_COURSE: 'DELETE_REQUEST',
  UPDATE_COURSE_REQUEST: 'UPDATE_COURSE_REQUEST',
  UPDATE_COURSE: 'UPDATE_COURSE',

  CREATE_SECTIONS_REQUEST: 'CREATE_SECTIONS_REQUEST',
  CREATE_SECTIONS: 'CREATE_SECTIONS',

  CREATE_SECTION_REQUEST: 'CREATE_SECTION_REQUEST',
  UPDATE_SECTION_REQUEST: 'UPDATE_SECTION_REQUEST',
  DELETE_SECTION_REQUEST: 'DELETE_SECTION_REQUEST',
  SECTION_DELETED: 'SECTION_DELETED',

  CREATE_SUB_SECTION_REQUEST: '  CREATE_SUB_SECTION_REQUEST',
  UPDATE_SUB_SECTION_REQUEST: 'UPDATE_SUB_SECTION_REQUEST',
  DELETE_SUB_SECTION_REQUEST: 'DELETE_SUB_SECTION_REQUEST',
  SUB_SECTION_DELETED: 'SUB_SECTION_DELETED',
  AUTHOR_COURSES_REQUEST: 'AUTHOR_COURSES_REQUEST',
  COURSES_REQUEST: 'COURSES_REQUEST',
  COURSES: 'COURSES',

  SINGLE_COURSE_REQUEST: 'SINGLE_COURSE_REQUEST',
  SINGLE_COURSE: 'SINGLE_COURSE',

  CLEARED_STATS: 'CLEARED_STATS',
  REQUEST_PROGRESS: 'REQUEST_PROGRESS',
  REQUEST_FAILED: 'REQUEST_FAILED',
  RESPONSE_FAILED: 'RESPONSE_FAILED',
  RESPONSE_SUCCESS: 'RESPONSE_SUCCESS',
  ASSIGN_COURSE: 'ASSIGN_COURSE',
  UN_ASSIGN_COURSE: 'UN_ASSIGN_COURSE',
};
export default types;
