import errorhandler from '@iso/config/ErrorHandler';

import SuperFetch from '@iso/lib/helpers/superFetch';
import axios from 'axios';
import siteConfig from '@iso/config/site.config';
const { apiUrl } = siteConfig;

async function blogCreate(payload, token) {
  return await axios({
    method: 'post',
    url: `${apiUrl}/createBlogs`,
    data: payload,
    headers: {
      'Content-Type': 'multipart/form-data boundary=abcde12345',
      Authorization: `Bearer ${token}`,
    },
  })
    .then(function(response) {
      return response.data;
    })
    .catch(function(err) {
      return errorhandler(err.response.data);
    });
}

async function blogDelete(id, token) {
  try {
    return await SuperFetch.delete(`/deleteBlog/${id}`, { token }).then(
      response => {
        if (response.error === 'jwt expired') {
          return errorhandler(response);
        }
        return response;
      }
    );
  } catch (err) {
    return errorhandler(err);
  }
}

async function blogList(token, page, size) {
  try {
    return await SuperFetch.get(`/all-blogs?pageNo=${page}&perPage=${size}`, {
      token,
    }).then(response => {
      if (response.error === 'jwt expired') {
        return errorhandler(response);
      }
      return response;
    });
  } catch (err) {
    return errorhandler(err);
  }
}
async function blog(token, id) {
  try {
    return await SuperFetch.get(`/blogById/${id}`, { token }).then(response => {
      if (response.error === 'jwt expired') {
        return errorhandler(response);
      }
      return response;
    });
  } catch (err) {
    return errorhandler(err);
  }
}
async function blogUpdate(id, data, token) {
  return await axios({
    method: 'put',
    url: `${apiUrl}/updateBlogs/${id}`,
    data: data,
    headers: {
      'Content-Type': 'multipart/form-data boundary=abcde12345',
      Authorization: `Bearer ${token}`,
    },
  })
    .then(function(response) {
      return response.data;
    })
    .catch(function(err) {
      return errorhandler(err.response.data);
    });
}

// Publish Blog
async function blogPublish(payload, token) {
  return await axios({
    method: 'post',
    url: `${apiUrl}/published-blog/${payload}`,
    headers: {
      'Content-Type': 'multipart/form-data boundary=abcde12345',
      Authorization: `Bearer ${token}`,
    },
  })
    .then(function(response) {
      return response.data;
    })
    .catch(function(err) {
      return errorhandler(err.response.data);
    });
}

// Unpublish Blog
async function blogUnpublish(payload, token) {
  return await axios({
    method: 'post',
    url: `${apiUrl}/unpublished-blog/${payload}`,
    headers: {
      'Content-Type': 'multipart/form-data boundary=abcde12345',
      Authorization: `Bearer ${token}`,
    },
  })
    .then(function(response) {
      return response.data;
    })
    .catch(function(err) {
      return errorhandler(err.response.data);
    });
}

export {
  blogCreate,
  blogList,
  blog,
  blogDelete,
  blogUpdate,
  blogPublish,
  blogUnpublish,
};
