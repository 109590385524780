// import axios from 'axios';
// import siteConfig from '@iso/config/site.config';
import errorhandler from '@iso/config/ErrorHandler';
import SuperFetch from '@iso/lib/helpers/superFetch';
// const formdata = new FormData();
// const { apiUrl } = siteConfig;

async function getTransactions(token, id) {
  try {
    if (id.length > 1) {
      return await SuperFetch.get(`/more-transactions/${id}`, {
        token,
      }).then(response => {
        if (response.error === 'jwt expired') {
          return errorhandler(response);
        }
        return response;
      });
    } else {
      return await SuperFetch.get(`/transactions`, {
        token,
      }).then(response => {
        if (response.error === 'jwt expired') {
          return errorhandler(response);
        }
        return response;
      });
    }
  } catch (err) {
    return errorhandler(err);
  }
}

export { getTransactions };
