import { all } from 'redux-saga/effects';
import authSagas from '@iso/redux/auth/saga';
import blogSagas from '@iso/redux/blog/saga';
import pageSagas from '@iso/redux/pages/saga';
import userSagas from '@iso/redux/user/saga';
import courseSagas from '@iso/redux/course/saga';
import purchaseSagas from '@iso/redux/purchase/saga';
import transactionSagas from '@iso/redux/transaction/saga';
import categorySagas from '@iso/redux/Category/saga';

export default function* rootSaga(getState) {
  yield all([
    authSagas(),
    blogSagas(),
    pageSagas(),
    userSagas(),
    courseSagas(),
    purchaseSagas(),
    transactionSagas(),
    categorySagas(),
  ]);
}
