import { all, takeEvery, put, fork, delay } from 'redux-saga/effects';

import _api from './api';

import _type from './types';

export function* categoriesRequest() {
  yield takeEvery(_type.CATEGORY_LIST_REQUEST, function*({ token }) {
    // yield put({ type: _type.LOGIN_PROGRESS });

    const response = yield _api.CategoryList(token);
    if (response.error) {
      yield put({ type: _type.RESPONSE_FAILED, payload: response.error });
    } else {
      yield put({
        type: _type.CATEGORIES,
        payload: response,
      });
    }
  });
}
export function* createCategoryRequest() {
  yield takeEvery(_type.CREATE_CATEGORY_REQUEST, function*({ token, data }) {
    // yield put({ type: _type.LOGIN_PROGRESS });

    const response = yield _api.createCategory(token, data);
    if (response.error) {
      yield put({ type: _type.RESPONSE_FAILED, payload: response.error });
    } else {
      yield put({
        type: _type.RESPONSE_SUCCESS,
        payload: 'New Category Created!',
      });
    }
    yield delay(1500);
    yield put({
      type: _type.CLEARED_STATS,
    });
  });
}
export function* deleteCategoryRequest() {
  yield takeEvery(_type.DELETE_REQUEST, function*({ token, id }) {
    // yield put({ type: _type.LOGIN_PROGRESS });

    const response = yield _api.DeleteCategory(token, id);
    if (response.error) {
      yield put({ type: _type.RESPONSE_FAILED, payload: response.error });
    } else {
      yield put({
        type: _type.RESPONSE_SUCCESS,
        payload: 'Category Deleted!',
      });
    }
    yield delay(1500);
    yield put({
      type: _type.CLEARED_STATS,
    });
  });
}

export function* updateCategoryRequest() {
  yield takeEvery(_type.UPDATE_REQUEST, function*({ token, id, data }) {
    // yield put({ type: _type.LOGIN_PROGRESS });
    const response = yield _api.updateCategory(token, id, data);
    if (response.error) {
      yield put({ type: _type.RESPONSE_FAILED, payload: response.error });
    } else {
      yield put({
        type: _type.RESPONSE_SUCCESS,
        payload: 'Category Has Been!',
      });
    }
    yield delay(1500);
    yield put({
      type: _type.CLEARED_STATS,
    });
  });
}

export default function* rootSaga() {
  yield all([
    fork(categoriesRequest),
    fork(createCategoryRequest),
    fork(deleteCategoryRequest),
    fork(updateCategoryRequest),
  ]);
}
