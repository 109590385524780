import _types from './types';
// import storage from 'redux-persist/lib/storage';
// import { persistReducer } from 'redux-persist';
const initState = {
  isProgress: false,
  isError: false,
  errorText: '',
  isSuccess: false,
  successText: '',
  newCourse: null,
  course: '',
  sections: '',
  subsections: '',
  courses: '',
};

function courseReducer(state = initState, action) {
  switch (action.type) {
    case _types.REQUEST_PROGRESS:
      return {
        ...state,
        isProgress: true,
      };
    case _types.CLEARED_STATS:
      return {
        ...state,
        isProgress: false,
        isError: false,
        isSuccess: false,
        successText: '',
        errorText: '',
        newCourse: '',
      };

    case _types.REQUEST_FAILED:
      return {
        ...state,
        isProgress: false,
        isSuccess: false,
        successText: '',
        isError: true,
        errorText: action.payload,
      };
    case _types.RESPONSE_FAILED:
      return {
        ...state,
        isProgress: false,
        isError: true,
        errorText: action.payload,
      };
    case _types.RESPONSE_SUCCESS:
      return {
        ...state,
        isProgress: false,
        isError: false,
        errorText: '',
        isSuccess: true,
        successText: action.payload,
      };
    case _types.CREATE_COURSE:
      return {
        ...state,
        isProgress: false,
        isError: false,
        errorText: '',
        isSuccess: false,
        successText: '',
        newCourse: action.payload,
      };
    case _types.COURSES:
      return {
        ...state,
        isProgress: false,
        isError: false,
        errorText: '',
        isSuccess: false,
        successText: '',
        courses: action.payload,
      };
    case _types.SINGLE_COURSE:
      return {
        ...state,
        isProgress: false,
        isError: false,
        errorText: '',
        isSuccess: false,
        successText: '',
        course: action.payload,
      };

    default:
      return state;
  }
}

export default courseReducer;
