import React from 'react';
import { PersistGate } from 'redux-persist/es/integration/react';
import { Provider } from 'react-redux';

import 'antd/dist/antd.css'; // or 'antd/dist/antd.less'
import { store, persistor } from './redux/store';

import Routes from './router';
import AppProvider from './AppProvider';

const App = () => (
  <Provider store={store}>
    <PersistGate loading={<div>Loading...</div>} persistor={persistor}>
      <AppProvider>
        <div>
          <Routes />
        </div>
      </AppProvider>
    </PersistGate>
  </Provider>
);

export default App;
