const types = {
  USERS_REQUEST: 'USERS_REQUEST',
  USERS: 'USERS',
  SINGLE_USER_REQUEST: 'SINGLE_USER_REQUEST',
  SINGLE_USER: 'SINGLE_USER',

  AUTHORS_REQUEST: 'AUTHORS_REQUEST',
  AUTHORS: 'AUTHORS',
  SINGLE_AUTHOR_REQUEST: 'SINGLE_AUTHOR_REQUEST',
  SINGLE_AUTHOR: 'SINGLE_AUTHOR',
  CREATE_AUTHOR_REQUEST: 'CREATE_AUTHOR_REQUEST',
  DELETE_AUTHOR_REQUEST: 'DELETE_AUTHOR_REQUEST',
  UPDATE_AUTHOR_REQUEST: 'UPDATE_AUTHOR_REQUEST',

  CREATE_SUB_ADMIN_REQUEST: 'CREATE_SUB_ADMIN_REQUEST',
  DELETE_SUB_ADMIN_REQUEST: 'DELETE_SUB_ADMIN_REQUEST',
  UPDATE_SUB_ADMIN_REQUEST: 'UPDATE_SUB_ADMIN_REQUEST',
  SUB_ADMINS_REQUEST: 'SUB_ADMINS_REQUEST',
  SUB_ADMINS: 'SUB_ADMINS',
  SUB_ADMIN_REQUEST: 'SUB_ADMIN_REQUEST',
  SINGLE_ADMIN: 'SINGLE_ADMIN',
  SUB_ADMIN_BLOCK: 'SUB_ADMIN_BLOCK',
  SUB_ADMIN_UNBLOCK: 'SUB_ADMIN_UNBLOCK',

  SINGLE_USER: 'SINGLE_USER',
  APP_USER_BLOCK: 'APP_USER_BLOCK',
  APP_USER_UNBLOCK: 'APP_USER_UNBLOCK',
  APP_USER_DELETE_REQUEST: 'APP_USER_DELETE_REQUEST',
  APP_USER_CREATE_REQUEST: 'APP_USER_CREATE_REQUEST',

  GET_USER_COURSES: 'GET_USER_COURSES',
  USERS_COURSES: 'USERS_COURSES',

  GET_AUTHOR_COURSES: 'GET_AUTHOR_COURSES',
  AUTHOR_COURSES: 'AUTHOR_COURSES',

  GET_AUTHOR_BLOGS: 'GET_AUTHOR_BLOGS',
  AUTHOR_BLOGS: 'AUTHOR_BLOGS',

  CLEARED_STATS: 'CLEARED_STATS',
  REQUEST_PROGRESS: 'REQUEST_PROGRESS',
  REQUEST_FAILED: 'REQUEST_FAILED',
  RESPONSE_FAILED: 'RESPONSE_FAILED',
  RESPONSE_SUCCESS: 'RESPONSE_SUCCESS',
};
export default types;
