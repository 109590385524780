import _types from './types';
import storage from 'redux-persist/lib/storage';
import { persistReducer } from 'redux-persist';
const initState = {
  idToken: null,
  isProgress: false,
  user: null,
  isError: false,
  errorText: '',
  isSuccess: false,
  successText: '',
  userStatics: null,
  profileImg: null,
};

function authReducer(state = initState, action) {
  switch (action.type) {
    case _types.LOGIN_PROGRESS:
      return {
        isProgress: true,
      };

    case _types.LOGIN_SUCCESS:
      const {
        address,
        email,
        fullName,
        phoneNumber,
        role,
        expireRefresh,
        refreshToken,
        expireToken,
        dpImg,
      } = action.payload;
      // console.log('action.payload', action.payload);
      return {
        isProgress: false,
        isError: false,
        errorText: '',
        idToken: action.payload.accessToken,
        expireRefresh: expireRefresh + Date.now(),
        expireToken: expireToken + Date.now(),
        refreshToken,
        user: { address, email, fullName, phoneNumber, role },
        profileImg: dpImg,
      };

    case _types.LOGIN_ERROR:
      return {
        isProgress: false,
        isError: true,
        errorText: action.payload,
      };

    case _types.REQUEST_FAILED:
      return {
        ...state,
        isProgress: false,
        isSuccess: false,
        successText: '',
        isError: true,
        errorText: action.payload,
      };
    case _types.RESPONSE_FAILED:
      return {
        ...state,
        isProgress: false,
        isError: true,
        errorText: action.payload,
      };
    case _types.RESPONSE_SUCCESS:
      return {
        ...state,
        isProgress: false,
        isError: false,
        errorText: '',
        isSuccess: true,
        successText: action.payload,
      };
    case _types.LOGOUT:
      return initState;

    case _types.UPDATE_ACCESS_TOKEN:
      return {
        ...state,
        idToken: action.payload.accessToken,
        expireRefresh: action.payload.expireRefresh + Date.now(),
        expireToken: action.payload.expireToken + Date.now(),
        refreshToken: action.payload.refreshToken,
      };
    case _types.CLEARED_STATS:
      return {
        ...state,
        isProgress: false,
        isError: false,
        errorText: '',
        isSuccess: false,
        successText: '',
      };
    case _types.SET_DASHBOARD_DATA:
      return {
        ...state,
        isProgress: false,
        isError: false,
        errorText: '',
        isSuccess: false,
        successText: '',
        userStatics: action.payload,
      };
    case _types.UPDATE_PROFILE:
      const { payload } = action;
      return {
        ...state,
        isProgress: false,
        isError: false,
        errorText: '',
        isSuccess: false,
        successText: '',
        user: {
          address: payload.address,
          email: payload.email,
          fullName: payload.fullName,
          phoneNumber: payload.phoneNumber,
          role: payload.role,
        },
      };
    case _types.UPDATE_IMG:
      // console.log('update image reducer', action.payload);
      return {
        ...state,
        isProgress: false,
        isError: false,
        errorText: '',
        isSuccess: false,
        successText: '',
        profileImg: action.payload.user.dpImg,
      };
    case _types.LOGOUT:
      return { ...state, initState };

    default:
      return state;
  }
}

const persistConfig = {
  key: 'authReducer',
  storage: storage,
  blacklist: ['isProgress', 'isError', 'errorText'],
  whitelist: [
    'user',
    'idToken',
    'accessToken',
    'expireRefresh',
    'refreshToken',
    'expireToken',
    'profileImg',
  ],
};

export default persistReducer(persistConfig, authReducer);
