import { store } from '../../../packages/isomorphic/src/redux/store';
import actions from '@iso/redux/auth/actions';
const { tokenRefresh } = actions;
function errorHandler(err) {
  if (err.response) {
    // client received an error response (5xx, 4xx)
    return err.response || err;
  } else if (err.request) {
    // client never received a response, or request never left
    return {
      status: 400,
      data: {
        error: 'The request timed out.',
      },
    };
  } else {
    // anything else
    if (err.error === 'jwt expired') {
      const refreshToken = store.getState().Auth.refreshToken;
      store.dispatch(tokenRefresh({ refreshToken }));
    }
    return err;
  }
}
export default errorHandler;
