const types = {
  CATEGORY_LIST_REQUEST: 'CATEGORY_LIST_REQUEST',
  CATEGORIES: 'CATEGORIES',
  CREATE_CATEGORY_REQUEST: 'CREATE_CATEGORY_REQUEST',
  DELETE_REQUEST: 'DELETE_REQUEST',
  UPDATE_REQUEST: 'UPDATE_REQUEST',
  CLEARED_STATS: 'CLEARED_STATS',
  REQUEST_PROGRESS: 'REQUEST_PROGRESS',
  REQUEST_FAILED: 'REQUEST_FAILED',
  RESPONSE_FAILED: 'RESPONSE_FAILED',
  RESPONSE_SUCCESS: 'RESPONSE_SUCCESS',
};

export default types;
