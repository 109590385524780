const types = {
  // CHECK_AUTHORIZATION: 'CHECK_AUTHORIZATION',
  PAGE_REQUEST: 'PAGE_REQUEST',
  PAGE_UPDATE_REQUEST: 'PAGE_EDIT_REQUEST',
  SINGLE_PAGE: ' SINGLE_PAGE',
  REQUEST_PROGRESS: 'REQUEST_PROGRESS',
  RESPONSE_SUCCESS: 'RESPONSE_SUCCESS',
  REQUEST_FAILED: 'REQUEST_FAILED',
  RESPONSE_FAILED: 'RESPONSE_FAILED',
  CLEARED_STATS: 'CLEARED_STATS',
};
export default types;
