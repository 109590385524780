import { all, delay, takeLatest, put, call } from 'redux-saga/effects';

import { getTransactions } from './api';

import _type from './types';

export function* transactionsRequest() {
  yield takeLatest(_type.TRANSACTIONS_REQUEST, function*({ token, id }) {
    const response = yield getTransactions(token, id);
    // const response=true;
    if (response.error) {
      if (response.error == 'jwt expired') return;
      yield put({
        type: _type.RESPONSE_FAILED,
        payload: response.error,
      });
    } else {
      if (id.length > 1) {
        yield put({
          type: _type.MORE_TRANSACTIONS,
          payload: response,
        });
      } else {
        yield put({
          type: _type.TRANSACTIONS,
          payload: response,
        });
      }
    }
  });
}

export default function* rootSaga() {
  yield all([call(transactionsRequest)]);
}
