import _types from './types';

const initState = {
  isProgress: false,
  isError: false,
  errorText: '',
  isSuccess: false,
  categories: null,
};

function userReducer(state = initState, action) {
  switch (action.type) {
    case _types.LOGIN_PROGRESS:
      return {
        isProgress: true,
      };

    case _types.REQUEST_FAILED:
      return {
        ...state,
        isProgress: false,
        isSuccess: false,
        successText: '',
        isError: true,
        errorText: action.payload,
      };
    case _types.RESPONSE_FAILED:
      return {
        ...state,
        isProgress: false,
        isError: true,
        errorText: action.payload,
      };

    case _types.RESPONSE_SUCCESS:
      return {
        ...state,
        isProgress: false,
        isError: false,
        errorText: '',
        isSuccess: true,
        successText: action.payload,
      };
    case _types.CLEARED_STATS:
      return {
        ...state,
        isError: false,
        isProgress: false,
        isSuccess: false,
        successText: '',
        errorText: '',
      };
    case _types.CATEGORIES:
      return {
        ...state,
        categories: action.payload,
      };

    default:
      return state;
  }
}

export default userReducer;
