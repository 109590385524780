import { all, takeEvery, put, fork, delay } from 'redux-saga/effects';
import { createBrowserHistory } from 'history';
import { getToken, clearToken } from '@iso/lib/helpers/utility';
import {
  resetPassword,
  loginUser,
  getAccessToken,
  getDashboardData,
  updateUser,
  updateUserImg,
} from './api';
// import errorHandler from '@iso/config/ErrorHandler';
import _type from './types';

const history = createBrowserHistory();
// const fakeApiCall = true; // auth0 or express JWT

export function* loginRequest() {
  yield takeEvery('LOGIN_REQUEST', function*({ payload }) {
    yield put({ type: _type.LOGIN_PROGRESS });
    const response = yield loginUser({ ...payload, state: 'PANEL' });

    if (response.error) {
      yield put({ type: _type.LOGIN_ERROR, payload: response.error });
      yield delay(5000);
      yield put({
        type: _type.CLEARED_STATS,
      });
    } else {
      yield put({
        type: _type.LOGIN_SUCCESS,
        payload: response,
      });
      yield put({
        type: _type.RESPONSE_SUCCESS,
        payload: 'Login',
      });
      yield put({
        type: _type.CLEARED_STATS,
      });
    }
  });
}
export function* unlockRequest() {
  yield takeEvery(_type.UNLOCK_REQUEST, function*({ payload }) {
    const response = yield loginUser({ ...payload, state: 'PANEL' });

    if (response.error) {
      yield put({
        type: _type.RESPONSE_FAILED,
        payload: response.error,
      });
    } else {
      yield put({
        type: _type.RESPONSE_SUCCESS,
        payload: 'User Unlocked Successfully.',
      });
      yield put({
        type: _type.LOGIN_SUCCESS,
        payload: response,
      });
    }
    yield delay(1500);
    yield put({
      type: _type.CLEARED_STATS,
    });
  });
}
export function* resetRequest() {
  yield takeEvery(_type.RESET_PASSWORD_REQUEST, function*({ token, data }) {
    yield put({ type: _type.REQUEST_PROGRESS });
    const response = yield resetPassword(data, token);

    if (response.error) {
      yield put({
        type: _type.RESPONSE_FAILED,
        payload:
          response.error === 'jwt expired' ? 'Session Expired' : response.error,
      });
    } else {
      yield put({
        type: _type.RESPONSE_SUCCESS,
        payload: 'Password Has Been Reset..',
      });
    }
    delay(1000);
    yield put({
      type: _type.CLEARED_STATS,
    });
  });
}

export function* logout() {
  yield takeEvery(_type.LOGOUT, function*() {
    yield clearToken();
    history.push('/');
  });
}

export function* refreshTokenRequest() {
  yield takeEvery(_type.TOKEN_REFRESH, function*({ refreshToken }) {
    const response = yield getAccessToken(refreshToken);
    // const response=true;
    if (response.error) {
      yield put({
        type: _type.RESPONSE_FAILED,
        payload:
          response.error === 'jwt expired' ? 'Session Expired' : response.error,
      });
    } else {
      yield put({
        type: _type.UPDATE_ACCESS_TOKEN,
        payload: response,
      });
    }
  });
}

export function* dashboardDataRequest() {
  yield takeEvery(_type.GET_DASHBOARD_DATA, function*({ token }) {
    const response = yield getDashboardData(token);

    if (response.error) {
      if (response.error === 'jwt expired') return;
      yield put({
        type: _type.RESPONSE_FAILED,
        payload: response.error,
      });
    } else {
      yield put({
        type: _type.SET_DASHBOARD_DATA,
        payload: response,
      });
    }
  });
}

export function* userUpdateRequest() {
  yield takeEvery(_type.UPDATE_USER_DATA, function*({ token, data }) {
    console.log('saga function', token, data);
    const response = yield updateUser(token, data);
    // const response=true;
    if (response.error) {
      yield put({
        type: _type.RESPONSE_FAILED,
        payload:
          response.error === 'jwt expired' ? 'Session Expired' : response.error,
      });
    } else {
      yield put({
        type: _type.RESPONSE_SUCCESS,
        payload: 'Profile Updated',
      });
      yield put({
        type: _type.UPDATE_PROFILE,
        payload: response,
      });
    }
    delay(1000);
    yield put({
      type: _type.CLEARED_STATS,
    });
  });
}

export function* imgUpdateRequest() {
  yield takeEvery(_type.UPDATE_PROFILE_IMG, function*({ token, data }) {
    console.log('data in saga', data);
    const response = yield updateUserImg(token, data);
    // const response=true;
    if (response.error) {
      yield put({
        type: _type.RESPONSE_FAILED,
        payload:
          response.error === 'jwt expired' ? 'Session Expired' : response.error,
      });
    } else {
      console.log('response of imgUpdateRequest', response);
      yield put({
        type: _type.RESPONSE_SUCCESS,
        payload: 'Image Updated',
      });
      yield put({
        type: _type.UPDATE_IMG,
        payload: response,
      });
    }
  });
}

export default function* rootSaga() {
  yield all([
    fork(loginRequest),
    fork(resetRequest),
    fork(unlockRequest),
    fork(logout),
    fork(refreshTokenRequest),
    fork(dashboardDataRequest),
    fork(userUpdateRequest),
    fork(imgUpdateRequest),
  ]);
}
