import _types from './types';
// import storage from 'redux-persist/lib/storage';
// import { persistReducer } from 'redux-persist';
const initState = {
  isProgress: false,
  isError: false,
  errorText: '',
  isSuccess: false,
  successText: '',
  transactions: '',
};

function courseReducer(state = initState, action) {
  switch (action.type) {
    case _types.REQUEST_PROGRESS:
      return {
        ...state,
        isProgress: true,
      };
    case _types.CLEARED_STATS:
      return {
        ...state,
        isProgress: false,
        isError: false,
        isSuccess: false,
        successText: '',
        errorText: '',
      };

    case _types.REQUEST_FAILED:
      return {
        ...state,
        isProgress: false,
        isSuccess: false,
        successText: '',
        isError: true,
        errorText: action.payload,
      };
    case _types.RESPONSE_FAILED:
      return {
        ...state,
        isProgress: false,
        isError: true,
        errorText: action.payload,
      };
    case _types.RESPONSE_SUCCESS:
      return {
        ...state,
        isProgress: false,
        isError: false,
        errorText: '',
        isSuccess: true,
        successText: action.payload,
      };
    case _types.TRANSACTIONS:
      return {
        ...state,
        isProgress: false,
        isError: false,
        errorText: '',
        isSuccess: false,
        successText: '',
        transactions: action.payload,
      };
    case _types.MORE_TRANSACTIONS:
      let currentData = state.transactions.data;
      let newData = action.payload.data;
      let hasMore = action.payload.has_more;
      return {
        ...state,
        isProgress: false,
        isError: false,
        errorText: '',
        isSuccess: false,
        successText: '',
        transactions: { data: currentData.concat(newData), has_more: hasMore },
      };

    default:
      return state;
  }
}

export default courseReducer;
