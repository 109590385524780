// import axios from 'axios';
// import siteConfig from '@iso/config/site.config';
import errorhandler from '@iso/config/ErrorHandler';
import SuperFetch from '@iso/lib/helpers/superFetch';
// const formdata = new FormData();
// const { apiUrl } = siteConfig;

async function getPurchases(token, page, pageSize) {
  try {
    return await SuperFetch.get(`/orders?pageNo=${page}&perPage=${pageSize}`, {
      token,
    }).then(response => {
      const res = {
        orders: response.orders.map(res => ({
          audit: res.audit,
          buyerId: res.buyerId,
          buyerName: res.buyerName,
          completedSections: res.completedSections,
          courseId: res.courseId,
          creatorId: res.creatorId,
          creatorName: res.creatorName,
          email: res.email,
          title: res.title,
          _id: res._id,
          key: res._id,
        })),
        pageNo: response.pageNo,
        perPage: response.perPage,
        total: response.total,
      };
      if (response.error === 'jwt expired') {
        return errorhandler(response);
      }

      return response;
    });
  } catch (err) {
    return errorhandler(err);
  }
}

async function getPurchasesOrders(token, page, pageSize) {
  try {
    return await SuperFetch.get(
      `/orders-purchased?pageNo=${page}&perPage=${pageSize}`,
      {
        token,
      }
    ).then(response => {
      const res = {
        orders: response.orders.map(res => ({
          audit: res.audit,
          buyerId: res.buyerId,
          buyerName: res.buyerName,
          completedSections: res.completedSections,
          courseId: res.courseId,
          creatorId: res.creatorId,
          creatorName: res.creatorName,
          email: res.email,
          title: res.title,
          _id: res._id,
          key: res._id,
        })),
        pageNo: response.pageNo,
        perPage: response.perPage,
        total: response.total,
      };
      if (response.error === 'jwt expired') {
        return errorhandler(response);
      }
      return response;
    });
  } catch (err) {
    return errorhandler(err);
  }
}

export { getPurchases, getPurchasesOrders };
