import { all, takeEvery, put, fork, delay } from 'redux-saga/effects';
import { page, pageUpdate } from './api';
import _type from './types';

export function* pageRequest() {
  yield takeEvery(_type.PAGE_REQUEST, function*({ token, pageName }) {
    // yield put({ type: _type.REQUEST_PROGRESS });
    yield put({
      type: _type.CLEARED_STATS,
    });
    const response = yield page(token, pageName);
    if (!response.error) {
      yield put({
        type: _type.SINGLE_PAGE,
        payload: response,
      });
    } else {
      if (response.error == 'jwt expired') return;
      yield put({
        type: _type.RESPONSE_FAILED,
        payload: response.error,
      });
    }
  });
}
export function* pageUpdateRequest() {
  yield takeEvery(_type.PAGE_UPDATE_REQUEST, function*({ token, page, data }) {
    yield put({ type: _type.REQUEST_PROGRESS });
    const response = yield pageUpdate(token, page, data);
    if (!response.error) {
      yield put({
        type: _type.RESPONSE_SUCCESS,
        payload: 'Page Has Been Updated',
      });
      // delay(1000);
      // yield put({
      //   type: _type.CLEARED_STATS,
      // });
    } else {
      yield put({
        type: _type.RESPONSE_FAILED,
        payload:
          response.error === 'jwt expired' ? 'Session Expired' : response.error,
      });
    }
  });
}

export default function* rootSaga() {
  yield all([fork(pageRequest), fork(pageUpdateRequest)]);
}
