import _types from './types';
// import storage from 'redux-persist/lib/storage';
// import { persistReducer } from 'redux-persist';
const initState = {
  isProgress: false,
  isError: false,
  errorText: '',
  isSuccess: false,
  successText: '',
  data: null,
  list: null,
  blog: null,
};

function blogReducer(state = initState, action) {
  switch (action.type) {
    case _types.REQUEST_PROGRESS:
      return {
        ...state,
        isProgress: true,
      };
    case _types.CREATE_SUCCESSFUL:
      return {
        ...state,
        data: action.payload,
      };

    case _types.REQUEST_FAILED:
      return {
        ...state,
        isProgress: false,
        isSuccess: false,
        successText: '',
        isError: true,
        errorText: action.payload,
      };
    case _types.RESPONSE_FAILED:
      return {
        ...state,
        isProgress: false,
        isError: true,
        errorText: action.payload,
      };
    case _types.RESPONSE_SUCCESS:
      return {
        ...state,
        isProgress: false,
        isError: false,
        errorText: '',
        isSuccess: true,
        successText: action.payload,
      };
    case _types.BLOG_LIST:
      return {
        ...state,
        isProgress: false,
        isError: false,
        errorText: '',
        isSuccess: false,
        successText: '',
        list: action.payload,
      };
    case _types.SINGLE_BLOG:
      return {
        ...state,
        isProgress: false,
        isError: false,
        errorText: '',
        isSuccess: false,
        successText: '',
        blog: action.payload,
      };
    case _types.CLEARED_STATS:
      return {
        isProgress: false,
        isError: false,
        errorText: '',
        isSuccess: false,
        successText: '',
        blog: null,
      };

    default:
      return state;
  }
}

export default blogReducer;
