import errorhandler from '@iso/config/ErrorHandler';
// const formData = new FormData();

import SuperFetch from '@iso/lib/helpers/superFetch';

async function page(token, page) {
  try {
    return await SuperFetch.get(`/get-${page}`, { token }).then(response => {
      if (response.error === 'jwt expired') {
        return errorhandler(response);
      }
      return response;
    });
  } catch (err) {
    return errorhandler(err);
  }
}
async function pageUpdate(token, page, data) {
  try {
    return await SuperFetch.put(`/put-${page}`, { data, token }).then(
      response => {
        if (response.error === 'jwt expired') {
          return errorhandler(response);
        }
        return response;
      }
    );
  } catch (err) {
    return errorhandler(err);
  }
}

export { page, pageUpdate };
