import errorhandler from '@iso/config/ErrorHandler';
import SuperFetch from '@iso/lib/helpers/superFetch';
import axios from 'axios';
import { apiUrl } from '@iso/config/site.config';
async function Users(token, page, size) {
  try {
    return await SuperFetch.get(`/users?pageNo=${page}&perPage=${size}`, {
      token,
    }).then(response => {
      if (response.error === 'jwt expired') {
        return errorhandler(response);
      }
      return response;
    });
  } catch (err) {
    return errorhandler(err);
  }
}

async function Authors(token, page, size) {
  try {
    return await SuperFetch.get(`/authors?pageNo=${page}&perPage=${size}`, {
      token,
    }).then(response => {
      if (response.error === 'jwt expired') {
        return errorhandler(response);
      }
      return response;
    });
  } catch (err) {
    return errorhandler(err);
  }
}

async function CreateAuthor(token, data) {
  try {
    return await SuperFetch.post(`/create-author`, {
      token,
      data,
    }).then(response => {
      if (response.error === 'jwt expired') {
        return errorhandler(response);
      }
      return response;
    });
  } catch (err) {
    return errorhandler(err);
  }
}
async function CreateSubAdmin(token, data) {
  try {
    return await SuperFetch.post(`/create-sub-admin`, {
      token,
      data,
    }).then(response => {
      if (response.error === 'jwt expired') {
        return errorhandler(response);
      }
      return response;
    });
  } catch (err) {
    return errorhandler(err);
  }
}
async function UpdateSubAdmin(id, data, token) {
  try {
    return await SuperFetch.patch(`/update-profile/${id}`, {
      token,
      data,
    }).then(response => {
      if (response.error === 'jwt expired') {
        return errorhandler(response);
      }
      return response;
    });
  } catch (err) {
    return errorhandler(err);
  }
}
async function SubAdmins(token, page, size) {
  try {
    return await SuperFetch.get(`/sub-admins?pageNo=${page}&perPage=${size}`, {
      token,
    }).then(response => {
      if (response.error === 'jwt expired') {
        return errorhandler(response);
      }
      return response;
    });
  } catch (err) {
    return errorhandler(err);
  }
}
async function BlockSubAdmin(id, token) {
  try {
    return await SuperFetch.patch(`/sub-admin/${id}/block`, { token }).then(
      response => {
        if (response.error === 'jwt expired') {
          return errorhandler(response);
        }
        return response;
      }
    );
  } catch (err) {
    return errorhandler(err);
  }
}
async function UnBlockSubAdmin(id, token) {
  try {
    return await SuperFetch.patch(`/sub-admin/${id}/active`, { token }).then(
      response => {
        if (response.error === 'jwt expired') {
          return errorhandler(response);
        }
        return response;
      }
    );
  } catch (err) {
    return errorhandler(err);
  }
}

async function CreateUser(data) {
  const appAPi = apiUrl.replace('/admin', '');
  return await axios({
    method: 'POST',
    url: `${appAPi}/app/register`,
    data: data,
  })
    .then(function(response) {
      //handle success
      return response.data;
    })
    .catch(function(err) {
      //handle error
      return errorhandler(err.response.data);
    });
}

async function UpdateAuthor(id, data, token) {
  try {
    return await SuperFetch.patch(`/update-profile/${id}`, {
      token,
      data,
    }).then(response => {
      if (response.error === 'jwt expired') {
        return errorhandler(response);
      }
      return response;
    });
  } catch (err) {
    return errorhandler(err);
  }
}

async function BlockUser(id, token) {
  try {
    return await SuperFetch.patch(`/user/${id}/block`, { token }).then(
      response => {
        if (response.error === 'jwt expired') {
          return errorhandler(response);
        }
        return response;
      }
    );
  } catch (err) {
    return errorhandler(err);
  }
}
async function deleteAuthor(id, token) {
  try {
    return await SuperFetch.delete(`/author/${id}`, { token }).then(
      response => {
        if (response.error === 'jwt expired') {
          return errorhandler(response);
        }
        return response;
      }
    );
  } catch (err) {
    return errorhandler(err);
  }
}

async function deleteSubAdmin(id, token) {
  try {
    return await SuperFetch.delete(`/sub-admin/${id}`, { token }).then(
      response => {
        if (response.error === 'jwt expired') {
          return errorhandler(response);
        }
        return response;
      }
    );
  } catch (err) {
    return errorhandler(err);
  }
}

async function deleteUser(id, token) {
  try {
    return await SuperFetch.delete(`/user/${id}`, { token }).then(response => {
      if (response.error === 'jwt expired') {
        return errorhandler(response);
      }
      return response;
    });
  } catch (err) {
    return errorhandler(err);
  }
}

async function UnBlockUser(id, token) {
  try {
    return await SuperFetch.patch(`/user/${id}/active`, { token }).then(
      response => {
        if (response.error === 'jwt expired') {
          return errorhandler(response);
        }
        return response;
      }
    );
  } catch (err) {
    return errorhandler(err);
  }
}
async function SubAdmin(id, token) {
  try {
    return await SuperFetch.get(`/sub-admin/${id}`, { token }).then(
      response => {
        if (response.error === 'jwt expired') {
          return errorhandler(response);
        }
        return response;
      }
    );
  } catch (err) {
    return errorhandler(err);
  }
}
async function Author(id, token) {
  try {
    return await SuperFetch.get(`/author/${id}`, { token }).then(response => {
      if (response.error === 'jwt expired') {
        return errorhandler(response);
      }
      return response;
    });
  } catch (err) {
    return errorhandler(err);
  }
}
async function User(id, token) {
  try {
    return await SuperFetch.get(`/user/${id}`, { token }).then(response => {
      if (response.error === 'jwt expired') {
        return errorhandler(response);
      }
      return response;
    });
  } catch (err) {
    return errorhandler(err);
  }
}
async function getUserCourses(token, id) {
  try {
    return await SuperFetch.get(`/user-courses/${id}`, { token }).then(
      response => {
        if (response.error === 'jwt expired') {
          return errorhandler(response);
        }
        return response;
      }
    );
  } catch (err) {
    return errorhandler(err);
  }
}
async function getAuthorCourses(token, id) {
  try {
    return await SuperFetch.get(`/author-courses/${id}`, { token }).then(
      response => {
        if (response.error === 'jwt expired') {
          return errorhandler(response);
        }
        return response;
      }
    );
  } catch (err) {
    return errorhandler(err);
  }
}
async function getAuthorBlogs(token, id) {
  try {
    // Here we need to change blogById with new api for author's personal blogs.
    return await SuperFetch.get(`/blogs/${id}`, { token }).then(response => {
      if (response.error === 'jwt expired') {
        return errorhandler(response);
      }
      return response;
    });
  } catch (err) {
    return errorhandler(err);
  }
}

function getFilterUser(token, arg) {
  return axios({
    method: 'GET',
    url: `${apiUrl}/users-filter/${arg}`,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  }).then(function(response) {
    return response.data;
  });
}

export {
  Users,
  User,
  Authors,
  Author,
  deleteAuthor,
  CreateAuthor,
  CreateSubAdmin,
  UpdateAuthor,
  BlockUser,
  UnBlockUser,
  deleteUser,
  CreateUser,
  getUserCourses,
  getAuthorCourses,
  getAuthorBlogs,
  SubAdmins,
  UpdateSubAdmin,
  BlockSubAdmin,
  deleteSubAdmin,
  UnBlockSubAdmin,
  SubAdmin,
  getFilterUser,
};
