import { apiUrl } from '@iso/config/site.config';

const customHeader = (token = '', lang) => ({
  'Content-Type': 'application/json',
  Accept: 'application/json',
  Authorization: 'Bearer ' + token,
  'Accept-language': lang,
});

const SuperFetch = {};

SuperFetch.get = (url, { data, token, lang = 'en' }) =>
  fetch(`${apiUrl}${url}`, {
    method: 'get',
    headers: customHeader(token, lang),
    body: JSON.stringify(data),
  })
    .then(response => response.json())
    .then(res => res)
    .catch(error => ({ error: 'Server Error' }));

SuperFetch.post = (url, { data, token, lang = 'en' }) =>
  fetch(`${apiUrl}${url}`, {
    method: 'post',
    headers: customHeader(token, lang),
    body: JSON.stringify(data),
  })
    .then(response => response.json())
    .then(res => res)
    .catch(error => ({ error: 'Server Error' }));
SuperFetch.put = (url, { data, token, lang = 'en' }) =>
  fetch(`${apiUrl}${url}`, {
    method: 'put',
    headers: customHeader(token, lang),
    body: JSON.stringify(data),
  })
    .then(response => response.json())
    .then(res => res)
    .catch(error => ({ error: 'Server Error' }));
SuperFetch.delete = (url, { data, token, lang = 'en' }) =>
  fetch(`${apiUrl}${url}`, {
    method: 'delete',
    headers: customHeader(token, lang),
    body: JSON.stringify(data),
  })
    .then(response => response.json())
    .then(res => res)
    .catch(error => ({ error: 'Server Error' }));

SuperFetch.patch = (url, { data, token, lang = 'en' }) =>
  fetch(`${apiUrl}${url}`, {
    method: 'PATCH',
    headers: customHeader(token, lang),
    body: JSON.stringify(data),
  })
    .then(response => response.json())
    .then(res => res)
    .catch(error => ({ error: 'Server Error' }));

// const base = (method, url, data,token) => {
//   return fetch(`${apiUrl}${url}`, {
//     method,
//     headers: customHeader(token),
//     body: JSON.stringify(data),
//   })
//     .then(response => response.json())
//     .then(res => res)
//     .catch(error => ({ error: 'Server Error' }));
// };

// const SuperFetch = {};
// ['get', 'post', 'put', 'delete'].forEach(method => {
//   SuperFetch[method] = base.bind(null, method);
// });
export default SuperFetch;
