import React, { lazy, Suspense } from 'react';
import {
  Route,
  Redirect,
  BrowserRouter as Router,
  Switch,
} from 'react-router-dom';
import { useSelector } from 'react-redux';

import ErrorBoundary from './ErrorBoundary';
import { PUBLIC_ROUTE } from './route.constants';
import Loader from '@iso/components/utility/loader';

const Dashboard = lazy(() => import('./containers/Dashboard/Dashboard'));

const publicRoutes = [
  {
    path: PUBLIC_ROUTE.LANDING,
    exact: true,
    component: lazy(() => import('@iso/containers/Pages/SignIn/SignIn')),
  },
  {
    path: PUBLIC_ROUTE.LOCKED,
    component: lazy(() => import('@iso/containers/Pages/Locked/Locked')),
  },
  {
    path: PUBLIC_ROUTE.PAGE_404,
    component: lazy(() => import('@iso/containers/Pages/404/404')),
  },

  {
    path: PUBLIC_ROUTE.SIGN_IN,
    component: lazy(() => import('@iso/containers/Pages/SignIn/SignIn')),
  },
  {
    path: PUBLIC_ROUTE.THANKS,
    component: lazy(() => import('@iso/containers/Pages/Thanks/')),
  },
  {
    path: PUBLIC_ROUTE.RESET_PASSWORD,
    component: lazy(() =>
      import('@iso/containers/Pages/ResetPassword/ResetPassword')
    ),
  },
];

function PrivateRoute({ children, ...rest }) {
  const isLoggedIn = useSelector(state => state.Auth.idToken);
  const isExpireRefresh = useSelector(state => state.Auth.expireToken);
  let time = isExpireRefresh > Date.now();
  if (isLoggedIn) {
    if (!time) {
      return <Redirect to="/Locked" />;
    }
    return <Route {...rest}>{children}</Route>;
  } else {
    return <Redirect to={'/signin'} />;
  }
}

export default function Routes() {
  return (
    <ErrorBoundary>
      <Suspense fallback={<Loader />}>
        <Router>
          <Switch>
            {publicRoutes.map((route, index) => (
              <Route key={index} path={route.path} exact={route.exact}>
                <route.component />
              </Route>
            ))}
            <PrivateRoute path="/dashboard">
              <Dashboard />
            </PrivateRoute>
            <Route
              component={lazy(() => import('@iso/containers/Pages/404/404'))}
            />
          </Switch>
        </Router>
      </Suspense>
    </ErrorBoundary>
  );
}
