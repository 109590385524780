import _type from './types';

const actions = {
  // checkAuthorization: () => ({ type: _type.CHECK_AUTHORIZATION }),
  login: payload => ({
    type: _type.LOGIN_REQUEST,
    payload: payload,
  }),
  unlock: payload => ({
    type: _type.UNLOCK_REQUEST,
    payload: payload,
  }),
  resetPass: payload => ({
    type: _type.RESET_PASSWORD_REQUEST,
    token: payload.token,
    data: payload.values,
  }),

  logout: () => ({
    type: _type.LOGOUT,
  }),

  tokenRefresh: payload => ({
    type: _type.TOKEN_REFRESH,
    refreshToken: payload.refreshToken,
  }),

  getDashboard: payload => ({
    type: _type.GET_DASHBOARD_DATA,
    token: payload.token,
  }),

  updateUserProfile: payload => ({
    type: _type.UPDATE_USER_DATA,
    token: payload.token,
    data: payload.values,
  }),

  updateProfileImg: payload => ({
    ...console.log(payload.values),
    type: _type.UPDATE_PROFILE_IMG,
    token: payload.token,
    data: payload.values,
  }),
};
export default actions;
