import { all, delay, takeLatest, put, call } from 'redux-saga/effects';

import { getPurchases, getPurchasesOrders } from './api';

import _type from './types';

export function* purchasesRequest() {
  yield takeLatest(_type.PURCHASE_REQUEST, function*({
    token,
    page,
    pageSize,
  }) {
    const response = yield getPurchases(token, page, pageSize);
    // const response=true;
    if (response.error) {
      if (response.error == 'jwt expired') return;
      yield put({
        type: _type.RESPONSE_FAILED,
        payload: response.error,
      });
    } else {
      yield put({
        type: _type.PURCHASES,
        payload: response,
      });
    }
  });
}

export function* PurchasedOrderRequest() {
  yield takeLatest(_type.PURCHASED_ORDER_REQUEST, function*({
    token,
    page,
    pageSize,
  }) {
    const response = yield getPurchasesOrders(token, page, pageSize);
    // const response=true;
    if (response.error) {
      if (response.error == 'jwt expired') return;
      yield put({
        type: _type.RESPONSE_FAILED,
        payload: response.error,
      });
    } else {
      yield put({
        type: _type.ORDER_PURCHASES,
        payload: response,
      });
    }
  });
}

export default function* rootSaga() {
  yield all([call(purchasesRequest), call(PurchasedOrderRequest)]);
}
