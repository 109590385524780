import { all, delay, takeLatest, put, call } from 'redux-saga/effects';

import {
  course,
  sectionsCreate,
  getCourses,
  deleteCourse,
  deleteSection,
  deleteSubSection,
  sectionCreate,
  subSectionCreate,
  sectionUpdate,
  sub_SectionUpdate,
  getCourse,
  courseUpdate,
  getAuthorCourses,
  setAssignCourse,
  UnAssignCourse,
} from './api';

import _type from './types';

export function* courseCreateRequest() {
  yield takeLatest(_type.CREATE_COURSE_REQUEST, function*({ token, data }) {
    yield put({
      type: _type.REQUEST_PROGRESS,
    });

    // const response=false;
    const response = yield course(data, token);
    if (response.error) {
      yield put({
        type: _type.RESPONSE_FAILED,
        payload:
          response.error === 'jwt expired' ? 'Session Expired' : response.error,
      });
    } else {
      yield put({
        type: _type.CREATE_COURSE,
        payload: response,
      });
    }
    delay(1000);
    yield put({
      type: _type.CLEARED_STATS,
    });
  });
}

export function* courseUpdateRequest() {
  yield takeLatest(_type.UPDATE_COURSE_REQUEST, function*({ token, data, id }) {
    yield put({
      type: _type.REQUEST_PROGRESS,
    });

    // const response=false;
    const response = yield courseUpdate(data, id, token);
    if (response.error) {
      yield put({
        type: _type.RESPONSE_FAILED,
        payload:
          response.error === 'jwt expired' ? 'Session Expired' : response.error,
      });
    } else {
      yield put({
        type: _type.RESPONSE_SUCCESS,
        payload: ' Course Has Been Updated Successfully..',
      });
    }
    delay(1000);
    yield put({
      type: _type.CLEARED_STATS,
    });
  });
}

export function* sectionsCreateRequest() {
  yield takeLatest(_type.CREATE_SECTIONS_REQUEST, function*({
    token,
    data,
    id,
  }) {
    yield put({
      type: _type.REQUEST_PROGRESS,
    });
    const response = yield sectionsCreate(data, id, token);
    // const response=true;
    if (response.error) {
      yield put({
        type: _type.RESPONSE_FAILED,
        payload:
          response.error === 'jwt expired' ? 'Session Expired' : response.error,
      });
    } else {
      yield put({
        type: _type.RESPONSE_SUCCESS,
        payload: 'New Course Has Been Created Successfully..',
      });
    }
    delay(1000);
    yield put({
      type: _type.CLEARED_STATS,
    });
  });
}

export function* sectionCreateRequest() {
  yield takeLatest(_type.CREATE_SECTION_REQUEST, function*({
    token,
    data,
    id,
  }) {
    yield put({
      type: _type.REQUEST_PROGRESS,
    });
    const response = yield sectionCreate(data, id, token);
    // const response=true;
    if (response.error) {
      yield put({
        type: _type.RESPONSE_FAILED,
        payload:
          response.error === 'jwt expired' ? 'Session Expired' : response.error,
      });
    } else {
      yield put({
        type: _type.RESPONSE_SUCCESS,
        payload: 'New Section Has Been Added!',
      });
    }
    delay(1000);
    yield put({
      type: _type.CLEARED_STATS,
    });
  });
}
export function* subSectionCreateRequest() {
  yield takeLatest(_type.CREATE_SUB_SECTION_REQUEST, function*({
    token,
    data,
    id,
    sID,
  }) {
    yield put({
      type: _type.REQUEST_PROGRESS,
    });
    const response = yield subSectionCreate(data, id, sID, token);
    // const response=true;
    if (response.error) {
      yield put({
        type: _type.RESPONSE_FAILED,
        payload:
          response.error === 'jwt expired' ? 'Session Expired' : response.error,
      });
    } else {
      yield put({
        type: _type.RESPONSE_SUCCESS,
        payload: 'New Sub Section Has Been Added!',
      });
    }
    delay(1000);
    yield put({
      type: _type.CLEARED_STATS,
    });
  });
}
export function* sectionUpdateRequest() {
  yield takeLatest(_type.UPDATE_SECTION_REQUEST, function*({
    token,
    data,
    id,
    sID,
  }) {
    yield put({
      type: _type.REQUEST_PROGRESS,
    });
    const response = yield sectionUpdate(data, id, sID, token);
    // const response=true;
    if (response.error) {
      yield put({
        type: _type.RESPONSE_FAILED,
        payload:
          response.error === 'jwt expired' ? 'Session Expired' : response.error,
      });
    } else {
      yield put({
        type: _type.RESPONSE_SUCCESS,
        payload: 'Sub Section Has Been Updated!',
      });
    }
    delay(1000);
    yield put({
      type: _type.CLEARED_STATS,
    });
  });
}
export function* subSectionUpdateRequest() {
  yield takeLatest(_type.UPDATE_SUB_SECTION_REQUEST, function*({
    token,
    data,
    id,
    sID,
    subID,
  }) {
    yield put({
      type: _type.REQUEST_PROGRESS,
    });
    const response = yield sub_SectionUpdate(data, id, sID, subID, token);
    // const response=true;
    if (response.error) {
      yield put({
        type: _type.RESPONSE_FAILED,
        payload:
          response.error === 'jwt expired' ? 'Session Expired' : response.error,
      });
    } else {
      yield put({
        type: _type.RESPONSE_SUCCESS,
        payload: 'Sub Section Has Been Updated!',
      });
    }
    delay(1000);
    yield put({
      type: _type.CLEARED_STATS,
    });
  });
}
export function* sectionDeleteRequest() {
  yield takeLatest(_type.DELETE_SECTION_REQUEST, function*({ token, id, sID }) {
    yield put({
      type: _type.REQUEST_PROGRESS,
    });
    const response = yield deleteSection(id, sID, token);
    // const response=true;
    if (response.error) {
      yield put({
        type: _type.RESPONSE_FAILED,
        payload:
          response.error === 'jwt expired' ? 'Session Expired' : response.error,
      });
    } else {
      yield put({
        type: _type.RESPONSE_SUCCESS,
        payload: 'Section Has Been Deleted!',
      });
    }
    delay(1000);
    yield put({
      type: _type.CLEARED_STATS,
    });
  });
}

export function* subSectionDeleteRequest() {
  yield takeLatest(_type.DELETE_SUB_SECTION_REQUEST, function*({
    token,
    id,
    sID,
    subID,
  }) {
    yield put({
      type: _type.REQUEST_PROGRESS,
    });
    const response = yield deleteSubSection(id, sID, subID, token);
    // const response=true;
    if (response.error) {
      yield put({
        type: _type.RESPONSE_FAILED,
        payload:
          response.error === 'jwt expired' ? 'Session Expired' : response.error,
      });
    } else {
      yield put({
        type: _type.RESPONSE_SUCCESS,
        payload: 'Sub Section Has Been Deleted!',
      });
    }
    delay(1000);
    yield put({
      type: _type.CLEARED_STATS,
    });
  });
}

export function* coursesRequest() {
  yield takeLatest(_type.COURSES_REQUEST, function*({ token, page, pageSize }) {
    const response = yield getCourses(token, page, pageSize);
    // const response=true;
    if (response.error) {
      if (response.error == 'jwt expired') return;
      yield put({
        type: _type.RESPONSE_FAILED,
        payload: response.error,
      });
    } else {
      yield put({
        type: _type.COURSES,
        payload: response,
      });
    }
  });
}

export function* AuthorCoursesRequest() {
  yield takeLatest(_type.AUTHOR_COURSES_REQUEST, function*({
    token,
    page,
    pageSize,
  }) {
    const response = yield getAuthorCourses(token, page, pageSize);
    // const response=true;
    if (response.error) {
      if (response.error == 'jwt expired') return;
      yield put({
        type: _type.RESPONSE_FAILED,
        payload: response.error,
      });
    } else {
      yield put({
        type: _type.COURSES,
        payload: response,
      });
    }
  });
}

export function* deleteCoursesRequest() {
  yield takeLatest(_type.DELETE_COURSE_REQUEST, function*({ token, id }) {
    const response = yield deleteCourse(id, token);
    // const response=true;
    if (response.error) {
      yield put({
        type: _type.RESPONSE_FAILED,
        payload:
          response.error === 'jwt expired' ? 'Session Expired' : response.error,
      });
    } else {
      yield put({
        type: _type.RESPONSE_SUCCESS,
        payload: 'Course Deleted Success!',
      });
    }
    delay(1000);
    yield put({
      type: _type.CLEARED_STATS,
    });
  });
}
export function* CourseRequest() {
  yield takeLatest(_type.SINGLE_COURSE_REQUEST, function*({ token, id }) {
    const response = yield getCourse(id, token);
    // const response=true;
    if (response.error) {
      if (response.error == 'jwt expired') return;
      yield put({
        type: _type.RESPONSE_FAILED,
        payload: response.error,
      });
    } else {
      yield put({
        type: _type.SINGLE_COURSE,
        payload: response,
      });
    }
  });
}
export function* AssignRequest() {
  yield takeLatest(_type.ASSIGN_COURSE, function*({ token, courseId, userId }) {
    const response = yield setAssignCourse(token, courseId, userId);
    // const response=true;
    if (response.error) {
      yield put({
        type: _type.RESPONSE_FAILED,
        payload:
          response.error === 'jwt expired' ? 'Session Expired' : response.error,
      });
    } else {
      yield put({
        type: _type.RESPONSE_SUCCESS,
        payload: 'Course Assign Successfully',
      });
    }
    yield delay(2000);
    yield put({
      type: _type.CLEARED_STATS,
    });
  });
}
export function* UnAssignRequest() {
  yield takeLatest(_type.UN_ASSIGN_COURSE, function*({
    token,
    courseId,
    userId,
  }) {
    const response = yield UnAssignCourse(token, courseId, userId);
    // const response=true;
    if (response.error) {
      yield put({
        type: _type.RESPONSE_FAILED,
        payload:
          response.error === 'jwt expired' ? 'Session Expired' : response.error,
      });
    } else {
      yield put({
        type: _type.RESPONSE_SUCCESS,
        payload: 'Course Has Been Un Assign',
      });
    }
    yield delay(2000);
    yield put({
      type: _type.CLEARED_STATS,
    });
  });
}

export default function* rootSaga() {
  yield all([
    call(courseCreateRequest),
    call(sectionsCreateRequest),
    call(coursesRequest),
    call(deleteCoursesRequest),
    call(CourseRequest),
    call(sectionCreateRequest),
    call(subSectionCreateRequest),
    call(sectionUpdateRequest),
    call(subSectionUpdateRequest),
    call(sectionDeleteRequest),
    call(subSectionDeleteRequest),
    call(courseUpdateRequest),
    call(AuthorCoursesRequest),
    call(AssignRequest),
    call(UnAssignRequest),
  ]);
}
