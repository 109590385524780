const types = {
  // CHECK_AUTHORIZATION: 'CHECK_AUTHORIZATION',
  BLOG_CREATE_REQUEST: 'BLOG_CREATE_REQUEST',
  REQUEST_PROGRESS: 'REQUEST_PROGRESS',
  REQUEST_FAILED: 'REQUEST_FAILED',
  RESPONSE_FAILED: 'RESPONSE_FAILED',
  RESPONSE_SUCCESS: 'RESPONSE_SUCCESS',
  CREATE_SUCCESSFUL: 'CREATE_SUCCESSFUL',
  BLOG_LIST_REQUEST: 'BLOG_LIST_REQUEST',
  BLOG_LIST: 'BLOG_LIST',
  SINGLE_BLOG_REQUEST: 'SINGLE_BLOG_REQUEST',
  SINGLE_BLOG: 'SINGLE_BLOG',
  BLOG_DELETE_REQUEST: 'BLOG_DELETE_REQUEST',
  BLOG_UPDATE_REQUEST: 'BLOG_UPDATE_REQUEST',
  BLOG_PUBLISH_REQUEST: 'BLOG_PUBLISH_REQUEST',
  BLOG_UNPUBLISH_REQUEST: 'BLOG_UNPUBLISH_REQUEST',
  BLOG_UPDATED: 'BLOG_UPDATED',
  BLOG_DELETE: 'BLOG_DELETE',
  CLEARED_STATS: 'CLEARED_STATS',
};
export default types;
