import _types from './types';
// import storage from 'redux-persist/lib/storage';
// import { persistReducer } from 'redux-persist';
const initState = {
  isProgress: false,
  isError: false,
  errorText: '',
  isSuccess: false,
  successText: '',
  data: null,
};

function pagesReducer(state = initState, action) {
  switch (action.type) {
    case _types.REQUEST_PROGRESS:
      return {
        ...state,
        isProgress: true,
      };
    case _types.CLEARED_STATS:
      return initState;
    // case _types.CREATE_SUCCESSFUL:
    //   return {
    //     ...state,
    //     isProgress: false,
    //     isError: false,
    //     isSuccess: false,
    //     successText: '',
    //     errorText: '',
    //     data: action.payload,
    //   };

    case _types.REQUEST_FAILED:
      return {
        ...state,
        isProgress: false,
        isSuccess: false,
        successText: '',
        isError: true,
        errorText: action.payload,
      };
    case _types.RESPONSE_FAILED:
      return {
        ...state,
        isProgress: false,
        isError: true,
        errorText: action.payload,
      };
    case _types.RESPONSE_SUCCESS:
      return {
        ...state,
        isProgress: false,
        isError: false,
        errorText: '',
        isSuccess: true,
        successText: action.payload,
      };

    case _types.SINGLE_PAGE:
      return {
        ...state,
        isProgress: false,
        isError: false,
        errorText: '',
        isSuccess: false,
        successText: '',
        data: action.payload,
      };

    default:
      return state;
  }
}

export default pagesReducer;
