const types = {
  // CHECK_AUTHORIZATION: 'CHECK_AUTHORIZATION',
  TRANSACTIONS_REQUEST: 'TRANSACTIONS_REQUEST',
  TRANSACTIONS: 'TRANSACTIONS',
  MORE_TRANSACTIONS: 'MORE_TRANSACTIONS',

  CLEARED_STATS: 'CLEARED_STATS',
  REQUEST_PROGRESS: 'REQUEST_PROGRESS',
  REQUEST_FAILED: 'REQUEST_FAILED',
  RESPONSE_FAILED: 'RESPONSE_FAILED',
  RESPONSE_SUCCESS: 'RESPONSE_SUCCESS',
};
export default types;
