import { combineReducers } from 'redux';
import App from '@iso/redux/app/reducer';
import Auth from '@iso/redux/auth/reducer';
import Blog from '@iso/redux/blog/reducer';
import Pages from '@iso/redux/pages/reducer';
import Users from '@iso/redux/user/reducer';
import Course from '@iso/redux/course/reducer';
import Purchase from '@iso/redux/purchase/reducer';
import Transaction from '@iso/redux/transaction/reducer';
import ThemeSwitcher from '@iso/redux/themeSwitcher/reducer';
import LanguageSwitcher from '@iso/redux/languageSwitcher/reducer';
import Category from '@iso/redux/Category/reducer';

export default combineReducers({
  Auth,
  App,
  ThemeSwitcher,
  LanguageSwitcher,
  Blog,
  Pages,
  Users,
  Course,
  Purchase,
  Transaction,
  Category,
});
