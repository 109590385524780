import { all, takeLatest, put, fork, delay } from 'redux-saga/effects';
import {
  blogCreate,
  blogList,
  blogDelete,
  blog,
  blogUpdate,
  blogPublish,
  blogUnpublish,
} from './api';
import _type from './types';

// Create New Blog
export function* blogCreateRequest() {
  yield takeLatest(_type.BLOG_CREATE_REQUEST, function*({ payload, token }) {
    yield put({
      type: _type.REQUEST_PROGRESS,
    });
    const response = yield blogCreate(payload, token);

    if (response.error) {
      yield put({
        type: _type.RESPONSE_FAILED,
        payload:
          response.error === 'jwt expired' ? 'Session Expired' : response.error,
      });
    } else {
      yield put({
        type: _type.CREATE_SUCCESSFUL,
        payload: response,
      });
      yield put({
        type: _type.RESPONSE_SUCCESS,
        payload: 'New Blog Has Been Created!',
      });
      delay(2000);
      yield put({
        type: _type.CLEARED_STATS,
      });
    }
  });
}

// All Blog Request
export function* blogListFetch() {
  yield takeLatest(_type.BLOG_LIST_REQUEST, function*({
    token,
    page,
    pageSize,
  }) {
    yield put({
      type: _type.CLEARED_STATS,
    });
    const response = yield blogList(token, page, pageSize);
    if (response.error) {
      if (response.error === 'jwt expired') return;
      yield put({
        type: _type.RESPONSE_FAILED,
        payload: response.error,
      });
    } else {
      yield put({
        type: _type.BLOG_LIST,
        payload: response,
      });
    }
  });
}

// Single Blog
export function* blogRequest() {
  yield takeLatest(_type.SINGLE_BLOG_REQUEST, function*({ token, id }) {
    yield put({
      type: _type.CLEARED_STATS,
    });
    const response = yield blog(token, id);

    if (response.error) {
      if (response.error == 'jwt expired') return;
      yield put({
        type: _type.RESPONSE_FAILED,
        payload: response.error,
      });
    } else {
      yield put({
        type: _type.SINGLE_BLOG,
        payload: response,
      });
    }
  });
}

// Delete Blog
export function* deleteBlog() {
  yield takeLatest(_type.BLOG_DELETE_REQUEST, function*({ id, token }) {
    yield put({
      type: _type.REQUEST_PROGRESS,
    });
    const response = yield blogDelete(id, token);

    if (response.error) {
      yield put({
        type: _type.RESPONSE_FAILED,
        payload:
          response.error === 'jwt expired' ? 'Session Expired' : response.error,
      });
    } else {
      yield put({
        type: _type.RESPONSE_SUCCESS,
        payload: 'Blog Deleted Success',
      });
    }
  });
}

// Update Blog
export function* updateBlogRequest() {
  yield takeLatest(_type.BLOG_UPDATE_REQUEST, function*({ id, data, token }) {
    yield put({
      type: _type.REQUEST_PROGRESS,
    });
    const response = yield blogUpdate(id, data, token);
    if (response.error) {
      yield put({
        type: _type.RESPONSE_FAILED,
        payload:
          response.error === 'jwt expired' ? 'Session Expired' : response.error,
      });
    } else {
      yield put({
        type: _type.RESPONSE_SUCCESS,
        payload: 'Blog update Success',
      });
      yield delay(1000);
      yield put({
        type: _type.CLEARED_STATS,
      });
    }
  });
}

// Publish Blog
export function* publishBlogRequest() {
  yield takeLatest(_type.BLOG_PUBLISH_REQUEST, function*({ id, token }) {
    yield put({
      type: _type.REQUEST_PROGRESS,
    });
    const response = yield blogPublish(id, token);
    if (response.error) {
      yield put({
        type: _type.RESPONSE_FAILED,
        payload:
          response.error === 'jwt expired' ? 'Session Expired' : response.error,
      });
    } else {
      yield put({
        type: _type.RESPONSE_SUCCESS,
        payload: 'Blog Published',
      });
      delay(1000);
      yield put({
        type: _type.CLEARED_STATS,
      });
    }
  });
}

// Unpublish Blog
export function* unpublishBlogRequest() {
  yield takeLatest(_type.BLOG_UNPUBLISH_REQUEST, function*({ id, token }) {
    yield put({
      type: _type.REQUEST_PROGRESS,
    });
    const response = yield blogUnpublish(id, token);
    if (response.error) {
      yield put({
        type: _type.RESPONSE_FAILED,
        payload:
          response.error === 'jwt expired' ? 'Session Expired' : response.error,
      });
    } else {
      yield put({
        type: _type.RESPONSE_SUCCESS,
        payload: 'Blog Unpublished',
      });
      delay(1000);
      yield put({
        type: _type.CLEARED_STATS,
      });
    }
  });
}

export default function* rootSaga() {
  yield all([
    fork(blogCreateRequest),
    fork(blogListFetch),
    fork(deleteBlog),
    fork(blogRequest),
    fork(updateBlogRequest),
    fork(unpublishBlogRequest),
    fork(publishBlogRequest),
  ]);
}
