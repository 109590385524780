import axios from 'axios';
import siteConfig from '@iso/config/site.config';
import errorhandler from '@iso/config/ErrorHandler';
import SuperFetch from '@iso/lib/helpers/superFetch';
const { apiUrl } = siteConfig;

async function loginUser(payload) {
  try {
    return await SuperFetch.post('/login', { data: payload }).then(response => {
      if (response.error === 'jwt expired') {
        return errorhandler(response);
      }
      return response;
    });
  } catch (err) {
    return errorhandler(err);
  }
}
async function resetPassword(data, token) {
  const appAPi = apiUrl.replace('/admin', '');
  return await axios({
    method: 'post',
    url: `${appAPi}/app/reset-password`,
    headers: {
      Authorization: `Bearer ${token}`,
    },
    data: data,
  })
    .then(function(response) {
      //handle success

      return response.data;
    })
    .catch(function(err) {
      //handle error
      return errorhandler(err.response.data);
    });
}

// Function for Access Token
async function getAccessToken(token) {
  try {
    return await SuperFetch.get(`/access-token`, {
      token,
    }).then(response => {
      return response;
    });
  } catch (err) {
    return errorhandler(err);
  }
}

// Function for Dashboard Data
async function getDashboardData(token) {
  try {
    return await SuperFetch.get(`/statics`, {
      token,
    }).then(response => {
      if (response.error === 'jwt expired') {
        return errorhandler(response);
      }
      return response;
    });
  } catch (err) {
    return errorhandler(err);
  }
}

// Function for Update Profile Data
async function updateUser(token, data) {
  try {
    return await SuperFetch.patch(`/update-profile`, {
      token,
      data,
    }).then(response => {
      console.log('hello world', response);
      return response;
    });
  } catch (err) {
    console.log('error: ', error);
    return errorhandler(err);
  }
}

// Function for Update Profile Data
async function updateUserImg(token, data) {
  return await axios({
    method: 'patch',
    url: `${apiUrl}/update-profile-image-url`,
    data: data,
    headers: {
      'Content-Type': 'multipart/form-data boundary=abcde12345',
      Authorization: `Bearer ${token}`,
    },
  })
    .then(function(response) {
      console.log('hello response', response);
      return response.data;
    })
    .catch(function(err) {
      console.log('error: ', err.response);
      return errorhandler(err.response.data);
    });
}

export {
  loginUser,
  resetPassword,
  getAccessToken,
  getDashboardData,
  updateUser,
  updateUserImg,
};
