import _types from './types';

const initState = {
  isProgress: false,
  isError: false,
  isSuccess: false,
  successText: '',
  errorText: '',
  users: null,
  author: null,
  authors: null,
  user: null,
  subAdmin: null,
  singleSubAdmin: null,
};

function userReducer(state = initState, action) {
  switch (action.type) {
    case _types.USERS:
      return {
        ...state,
        isProgress: false,
        isSuccess: false,
        successText: '',
        isError: false,
        users: action.payload,
      };
    case _types.AUTHORS:
      return {
        ...state,
        isProgress: false,
        isSuccess: false,
        successText: '',
        isError: false,
        authors: action.payload,
      };
    case _types.SUB_ADMINS:
      return {
        ...state,
        isProgress: false,
        isSuccess: false,
        successText: '',
        isError: false,
        subAdmins: action.payload,
      };
    case _types.SINGLE_ADMIN:
      return {
        ...state,
        isProgress: false,
        isSuccess: false,
        successText: '',
        isError: false,
        singleSubAdmin: action.payload,
      };
    case _types.SINGLE_AUTHOR:
      return {
        ...state,
        isProgress: false,
        isSuccess: false,
        successText: '',
        isError: false,
        author: action.payload,
      };

    case _types.SINGLE_USER:
      return {
        ...state,
        isProgress: false,
        isSuccess: false,
        successText: '',
        isError: false,
        user: action.payload,
      };
    case _types.REQUEST_FAILED:
      return {
        ...state,
        isProgress: false,
        isSuccess: false,
        successText: '',
        isError: true,
        errorText: action.payload,
      };
    case _types.RESPONSE_FAILED:
      return {
        ...state,
        isProgress: false,
        isError: true,
        errorText: action.payload,
      };
    case _types.RESPONSE_SUCCESS:
      return {
        ...state,
        isProgress: false,
        isError: false,
        errorText: '',
        isSuccess: true,
        successText: action.payload,
      };
    case _types.CLEARED_STATS:
      return {
        ...state,
        isError: false,
        isProgress: false,
        isSuccess: false,
        successText: '',
        errorText: '',
        author: '',
      };
    case _types.USERS_COURSES:
      return {
        ...state,
        isError: false,
        isProgress: false,
        isSuccess: false,
        successText: '',
        errorText: '',
        userCourses: action.payload,
      };
    case _types.AUTHOR_COURSES:
      return {
        ...state,
        isError: false,
        isProgress: false,
        isSuccess: false,
        successText: '',
        errorText: '',
        authorCourses: action.payload,
      };
    case _types.AUTHOR_BLOGS:
      return {
        ...state,
        isError: false,
        isProgress: false,
        isSuccess: false,
        successText: '',
        errorText: '',
        authorBlogs: action.payload,
      };
    default:
      return state;
  }
}
export default userReducer;
