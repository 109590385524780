const types = {
  // CHECK_AUTHORIZATION: 'CHECK_AUTHORIZATION',
  PURCHASE_REQUEST: 'PURCHASE_REQUEST',
  PURCHASED_ORDER_REQUEST: 'PURCHASED_ORDER_REQUEST',
  PURCHASES: 'PURCHASES',
  ORDER_PURCHASES: 'ORDER_PURCHASES',

  CLEARED_STATS: 'CLEARED_STATS',
  REQUEST_PROGRESS: 'REQUEST_PROGRESS',
  REQUEST_FAILED: 'REQUEST_FAILED',
  RESPONSE_FAILED: 'RESPONSE_FAILED',
  RESPONSE_SUCCESS: 'RESPONSE_SUCCESS',
};
export default types;
