import SuperFetch from '@iso/lib/helpers/superFetch';
import axios from 'axios';
import siteConfig from '@iso/config/site.config';
const { apiUrl } = siteConfig;

const _api = {
  CategoryList: async function(token) {
    try {
      return await SuperFetch.get(`/categories`, { token }).then(response => {
        // console.log(response);
        return response;
      });
    } catch (err) {
      return err;
    }
  },
  DeleteCategory: async function(token, id) {
    try {
      return await SuperFetch.delete(`/category/${id}`, { token }).then(
        response => {
          // console.log(response);
          return response;
        }
      );
    } catch (err) {
      return err;
    }
  },
  updateCategory: async function(token, id, data) {
    return await axios({
      method: 'PATCH',
      url: `${apiUrl}/category/${id}`,
      data,
      headers: {
        'Content-Type': 'multipart/form-data boundary=abcde12345',
        Authorization: `Bearer ${token}`,
      },
    })
      .then(function(response) {
        return response.data;
      })
      .catch(function(err) {
        return err.response.data;
      });
  },

  createCategory: async function(token, data) {
    return await axios({
      method: 'post',
      url: `${apiUrl}/category`,
      data,
      headers: {
        'Content-Type': 'multipart/form-data boundary=abcde12345',
        Authorization: `Bearer ${token}`,
      },
    })
      .then(function(response) {
        return response.data;
      })
      .catch(function(err) {
        return err.response.data;
      });
  },
};

export default _api;
