import { all, takeEvery, put, delay, fork } from 'redux-saga/effects';
import {
  Users,
  User,
  Authors,
  Author,
  CreateAuthor,
  BlockUser,
  UnBlockUser,
  deleteAuthor,
  UpdateAuthor,
  deleteUser,
  CreateUser,
  getUserCourses,
  getAuthorCourses,
  getAuthorBlogs,
  CreateSubAdmin,
  SubAdmins,
  SubAdmin,
  UpdateSubAdmin,
  deleteSubAdmin,
  BlockSubAdmin,
  UnBlockSubAdmin,
} from './api';
import _type from './types';

export function* usersRequest() {
  yield takeEvery(_type.USERS_REQUEST, function*({ token, page, pageSize }) {
    yield put({ type: _type.CLEARED_STATS });
    const response = yield Users(token, page, pageSize);
    if (response.error) {
      if (response.error == 'jwt expired') return;
      yield put({
        type: _type.RESPONSE_FAILED,
        payload: response.error,
      });
    } else {
      yield put({
        type: _type.USERS,
        payload: response,
      });
    }
  });
}
export function* authorRequest() {
  yield takeEvery(_type.SINGLE_AUTHOR_REQUEST, function*({ token, id }) {
    const response = yield Author(id, token);
    if (response.error) {
      if (response.error == 'jwt expired') return;
      yield put({
        type: _type.RESPONSE_FAILED,
        payload: response.error,
      });
      delay(1000);
      yield put({
        type: _type.CLEARED_STATS,
      });
    } else {
      yield put({
        type: _type.SINGLE_AUTHOR,
        payload: response,
      });
    }
  });
}
export function* subAdminRequest() {
  yield takeEvery(_type.SUB_ADMIN_REQUEST, function*({ token, id }) {
    const response = yield SubAdmin(id, token);
    if (response.error) {
      if (response.error == 'jwt expired') return;
      yield put({
        type: _type.RESPONSE_FAILED,
        payload: response.error,
      });
      delay(1000);
      yield put({
        type: _type.CLEARED_STATS,
      });
    } else {
      yield put({
        type: _type.SINGLE_ADMIN,
        payload: response,
      });
    }
  });
}
export function* userRequest() {
  yield takeEvery(_type.SINGLE_USER_REQUEST, function*({ token, id }) {
    const response = yield User(id, token);
    if (response.error) {
      if (response.error == 'jwt expired') return;
      yield put({
        type: _type.RESPONSE_FAILED,
        payload: response.error,
      });
      delay(1000);
      yield put({
        type: _type.CLEARED_STATS,
      });
    } else {
      yield put({
        type: _type.SINGLE_USER,
        payload: response,
      });
    }
  });
}
export function* authorUpdateRequest() {
  yield takeEvery(_type.UPDATE_AUTHOR_REQUEST, function*({ token, data, id }) {
    const response = yield UpdateAuthor(id, data, token);
    if (response.error) {
      yield put({
        type: _type.RESPONSE_FAILED,
        payload:
          response.error === 'jwt expired' ? 'Session Expired' : response.error,
      });
    } else {
      yield put({
        type: _type.RESPONSE_SUCCESS,
        payload: 'Profile Updated',
      });
    }
    delay(1000);
    yield put({
      type: _type.CLEARED_STATS,
    });
  });
}
export function* subAdminUpdateRequest() {
  yield takeEvery(_type.UPDATE_SUB_ADMIN_REQUEST, function*({
    token,
    data,
    id,
  }) {
    const response = yield UpdateSubAdmin(id, data, token);
    if (response.error) {
      yield put({
        type: _type.RESPONSE_FAILED,
        payload:
          response.error === 'jwt expired' ? 'Session Expired' : response.error,
      });
    } else {
      yield put({
        type: _type.RESPONSE_SUCCESS,
        payload: 'Profile Updated',
      });
    }
    delay(1000);
    yield put({
      type: _type.CLEARED_STATS,
    });
  });
}
export function* authorsRequest() {
  yield takeEvery(_type.AUTHORS_REQUEST, function*({ token, page, pageSize }) {
    // yield put({ type: _type.REQUEST_PROGRESS });
    const response = yield Authors(token, page, pageSize);
    if (response.error) {
      if (response.error == 'jwt expired') return;
      yield put({
        type: _type.RESPONSE_FAILED,
        payload: response.error,
      });
    } else {
      yield put({
        type: _type.AUTHORS,
        payload: response,
      });
    }
  });
}
export function* SubAdminsRequest() {
  yield takeEvery(_type.SUB_ADMINS_REQUEST, function*({
    token,
    page,
    pageSize,
  }) {
    // yield put({ type: _type.REQUEST_PROGRESS });
    const response = yield SubAdmins(token, page, pageSize);
    if (response.error) {
      if (response.error == 'jwt expired') return;
      yield put({
        type: _type.RESPONSE_FAILED,
        payload: response.error,
      });
    } else {
      yield put({
        type: _type.SUB_ADMINS,
        payload: response,
      });
    }
  });
}
export function* userCourses() {
  yield takeEvery(_type.GET_USER_COURSES, function*({ token, id }) {
    // yield put({ type: _type.REQUEST_PROGRESS });
    const response = yield getUserCourses(token, id);
    if (response.error) {
      if (response.error == 'jwt expired') return;
      yield put({
        type: _type.RESPONSE_FAILED,
        payload: response.error,
      });
    } else {
      yield put({
        type: _type.USERS_COURSES,
        payload: response,
      });
    }
  });
}
export function* authorCourses() {
  yield takeEvery(_type.GET_AUTHOR_COURSES, function*({ token, id }) {
    // yield put({ type: _type.REQUEST_PROGRESS });
    const response = yield getAuthorCourses(token, id);
    if (response.error) {
      if (response.error == 'jwt expired') return;
      yield put({
        type: _type.RESPONSE_FAILED,
        payload: response.error,
      });
      delay(1000);
      yield put({
        type: _type.CLEARED_STATS,
      });
    } else {
      yield put({
        type: _type.AUTHOR_COURSES,
        payload: response,
      });
    }
  });
}
export function* authorBlogs() {
  yield takeEvery(_type.GET_AUTHOR_BLOGS, function*({ token, id }) {
    // yield put({ type: _type.REQUEST_PROGRESS });
    const response = yield getAuthorBlogs(token, id);
    if (response.error) {
      if (response.error == 'jwt expired') return;
      yield put({
        type: _type.RESPONSE_FAILED,
        payload: response.error,
      });
      delay(1000);
      yield put({
        type: _type.CLEARED_STATS,
      });
    } else {
      yield put({
        type: _type.AUTHOR_BLOGS,
        payload: response,
      });
    }
  });
}
export function* createAuthorRequest() {
  yield takeEvery(_type.CREATE_AUTHOR_REQUEST, function*({ token, data }) {
    yield put({ type: _type.REQUEST_PROGRESS });
    const response = yield CreateAuthor(token, data);
    if (response.error) {
      yield put({
        type: _type.RESPONSE_FAILED,
        payload:
          response.error === 'jwt expired' ? 'Session Expired' : response.error,
      });
    } else {
      yield put({
        type: _type.RESPONSE_SUCCESS,
        payload: 'New Author Has Been Created Successfully..',
      });
    }
    delay(1000);
    yield put({
      type: _type.CLEARED_STATS,
    });
  });
}
export function* createUserRequest() {
  yield takeEvery(_type.APP_USER_CREATE_REQUEST, function*({ data }) {
    yield put({ type: _type.REQUEST_PROGRESS });
    const response = yield CreateUser(data);
    if (response.error) {
      yield put({
        type: _type.RESPONSE_FAILED,
        payload: response.error,
      });
    } else {
      yield put({
        type: _type.RESPONSE_SUCCESS,
        payload: 'New User Has Been Created Successfully..',
      });
    }
    delay(1000);
    yield put({
      type: _type.CLEARED_STATS,
    });
  });
}

export function* createAdminRequest() {
  yield takeEvery(_type.CREATE_SUB_ADMIN_REQUEST, function*({ token, data }) {
    yield put({ type: _type.REQUEST_PROGRESS });
    const response = yield CreateSubAdmin(token, data);
    if (response.error) {
      yield put({
        type: _type.RESPONSE_FAILED,
        payload: response.error,
      });
    } else {
      yield put({
        type: _type.RESPONSE_SUCCESS,
        payload: 'New Sub Admin Has Been Created Successfully..',
      });
    }
    delay(1000);
    yield put({
      type: _type.CLEARED_STATS,
    });
  });
}

export function* blockUserRequest() {
  yield takeEvery(_type.APP_USER_BLOCK, function*({ token, id }) {
    yield put({ type: _type.REQUEST_PROGRESS });
    const response = yield BlockUser(id, token);
    if (response.error) {
      yield put({
        type: _type.RESPONSE_FAILED,
        payload:
          response.error === 'jwt expired' ? 'Session Expired' : response.error,
      });
    } else {
      yield put({
        type: _type.RESPONSE_SUCCESS,
        payload: 'The User Has been Blocked!',
      });
    }
    delay(1000);
    yield put({
      type: _type.CLEARED_STATS,
    });
  });
}
export function* unblockUserRequest() {
  yield takeEvery(_type.APP_USER_UNBLOCK, function*({ token, id }) {
    yield put({ type: _type.REQUEST_PROGRESS });
    const response = yield UnBlockUser(id, token);
    if (response.error) {
      yield put({
        type: _type.RESPONSE_FAILED,
        payload:
          response.error === 'jwt expired' ? 'Session Expired' : response.error,
      });
    } else {
      yield put({
        type: _type.RESPONSE_SUCCESS,
        payload: 'The User Has been UnBlocked!',
      });
    }
    delay(1000);
    yield put({
      type: _type.CLEARED_STATS,
    });
  });
}
export function* blockAdminRequest() {
  yield takeEvery(_type.SUB_ADMIN_BLOCK, function*({ token, id }) {
    yield put({ type: _type.REQUEST_PROGRESS });
    const response = yield BlockSubAdmin(id, token);
    if (response.error) {
      yield put({
        type: _type.RESPONSE_FAILED,
        payload:
          response.error === 'jwt expired' ? 'Session Expired' : response.error,
      });
    } else {
      yield put({
        type: _type.RESPONSE_SUCCESS,
        payload: 'The Sub Admin Has been Blocked!',
      });
    }
    delay(1000);
    yield put({
      type: _type.CLEARED_STATS,
    });
  });
}
export function* unblockAdminRequest() {
  yield takeEvery(_type.SUB_ADMIN_UNBLOCK, function*({ token, id }) {
    yield put({ type: _type.REQUEST_PROGRESS });
    const response = yield UnBlockSubAdmin(id, token);
    if (response.error) {
      yield put({
        type: _type.RESPONSE_FAILED,
        payload:
          response.error === 'jwt expired' ? 'Session Expired' : response.error,
      });
      delay(1000);
      yield put({
        type: _type.CLEARED_STATS,
      });
    } else {
      yield put({
        type: _type.RESPONSE_SUCCESS,
        payload: 'The Sub Admin Has been UnBlocked!',
      });
    }
  });
}
export function* deleteAuthorRequest() {
  yield takeEvery(_type.DELETE_AUTHOR_REQUEST, function*({ token, id }) {
    yield put({ type: _type.REQUEST_PROGRESS });
    const response = yield deleteAuthor(id, token);
    if (response.error) {
      yield put({
        type: _type.RESPONSE_FAILED,
        payload:
          response.error === 'jwt expired' ? 'Session Expired' : response.error,
      });
    } else {
      yield put({
        type: _type.RESPONSE_SUCCESS,
        payload: 'The Author Has been Deleted!',
      });
    }
    delay(1000);
    yield put({
      type: _type.CLEARED_STATS,
    });
  });
}
export function* deleteSubAdminRequest() {
  yield takeEvery(_type.DELETE_SUB_ADMIN_REQUEST, function*({ token, id }) {
    yield put({ type: _type.REQUEST_PROGRESS });
    const response = yield deleteSubAdmin(id, token);
    if (response.error) {
      yield put({
        type: _type.RESPONSE_FAILED,
        payload:
          response.error === 'jwt expired' ? 'Session Expired' : response.error,
      });
    } else {
      yield put({
        type: _type.RESPONSE_SUCCESS,
        payload: 'The Sub Admin Has been Deleted!',
      });
    }
    delay(1000);
    yield put({
      type: _type.CLEARED_STATS,
    });
  });
}
export function* deleteUserRequest() {
  yield takeEvery(_type.APP_USER_DELETE_REQUEST, function*({ token, id }) {
    yield put({ type: _type.REQUEST_PROGRESS });
    const response = yield deleteUser(id, token);
    if (response.error) {
      yield put({
        type: _type.RESPONSE_FAILED,
        payload:
          response.error === 'jwt expired' ? 'Session Expired' : response.error,
      });
    } else {
      yield put({
        type: _type.RESPONSE_SUCCESS,
        payload: 'The User Has been Deleted!',
      });
    }
    delay(1000);
    yield put({
      type: _type.CLEARED_STATS,
    });
  });
}

export default function* rootSaga() {
  yield all([
    fork(usersRequest),
    fork(authorsRequest),
    fork(createAuthorRequest),
    fork(blockUserRequest),
    fork(unblockUserRequest),
    fork(deleteAuthorRequest),
    fork(deleteUserRequest),
    fork(authorRequest),
    fork(authorUpdateRequest),
    fork(userRequest),
    fork(createUserRequest),
    fork(userCourses),
    fork(authorCourses),
    fork(authorBlogs),
    fork(createAdminRequest),
    fork(SubAdminsRequest),
    fork(subAdminRequest),
    fork(subAdminUpdateRequest),
    fork(deleteSubAdminRequest),
    fork(blockAdminRequest),
    fork(unblockAdminRequest),
  ]);
}
