export const PUBLIC_ROUTE = {
  LANDING: '/',
  SIGN_IN: '/signin',
  RESET_PASSWORD: '/mobile/reset-password',
  PAGE_404: '/404',
  LOCKED: '/locked',
  THANKS: '/thankyou',
};

export const PRIVATE_ROUTE = {
  DASHBOARD: '/',
};
