const types = {
  // CHECK_AUTHORIZATION: 'CHECK_AUTHORIZATION',
  LOGIN_REQUEST: 'LOGIN_REQUEST',
  LOGIN_PROGRESS: 'LOGIN_PROGRESS',
  LOGOUT_REQUEST: 'LOGOUT_REQUEST',
  LOGOUT: 'LOGOUT',
  UNLOCK_REQUEST: 'UNLOCK_REQUEST',
  UNLOCK: 'UNLOCK',
  LOGIN_SUCCESS: 'LOGIN_SUCCESS',
  LOGIN_ERROR: 'LOGIN_ERROR',
  FORGET_PASSWORD_REQUEST: 'FORGET_PASSWORD_REQUEST',
  FORGET_PASSWORD: 'FORGET_PASSWORD',
  RESET_PASSWORD_REQUEST: 'RESET_PASSWORD_REQUEST',
  RESET_PASSWORD: 'RESET_PASSWORD',

  UPDATE_USER_DATA: 'UPDATE_USER_DATA',
  UPDATE_PROFILE: 'UPDATE_PROFILE',

  UPDATE_PROFILE_IMG: 'UPDATE_PROFILE_IMG',
  UPDATE_IMG: 'UPDATE_IMG',

  CLEARED_STATS: 'CLEARED_STATS',
  REQUEST_PROGRESS: 'REQUEST_PROGRESS',
  REQUEST_FAILED: 'REQUEST_FAILED',
  RESPONSE_FAILED: 'RESPONSE_FAILED',
  RESPONSE_SUCCESS: 'RESPONSE_SUCCESS',
  UPDATE_ACCESS_TOKEN: 'UPDATE_ACCESS_TOKEN',

  TOKEN_REFRESH: 'TOKEN_REFRESH',
  GET_DASHBOARD_DATA: 'GET_DASHBOARD_DATA',
  SET_DASHBOARD_DATA: 'SET_DASHBOARD_DATA',
};
export default types;
