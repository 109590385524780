import axios from 'axios';
import siteConfig from '@iso/config/site.config';
import errorhandler from '@iso/config/ErrorHandler';
import SuperFetch from '@iso/lib/helpers/superFetch';
// const formdata = new FormData();
const { apiUrl } = siteConfig;

async function course(data, token) {
  return await axios({
    method: 'post',
    url: `${apiUrl}/create-course`,
    data: data,
    headers: {
      'Content-Type': 'multipart/form-data boundary=abcde12345',
      Authorization: `Bearer ${token}`,
    },
  })
    .then(function(response) {
      if (response.error === 'jwt expired') {
        return errorhandler(response);
      }
      //handle success
      return response.data;
    })
    .catch(function(err) {
      //handle error
      return errorhandler(err.response.data);
    });
}
async function courseUpdate(data, id, token) {
  return await axios({
    method: 'put',
    url: `${apiUrl}/course/${id}`,
    data: data,
    headers: {
      'Content-Type': 'multipart/form-data boundary=abcde12345',
      Authorization: `Bearer ${token}`,
    },
  })
    .then(function(response) {
      if (response.error === 'jwt expired') {
        return errorhandler(response);
      }
      //handle success
      return response.data;
    })
    .catch(function(err) {
      //handle error
      return errorhandler(err.response.data);
    });
}

async function sectionsCreate(data, id, token) {
  try {
    return await SuperFetch.post(`/course-sections/${id}`, {
      data,
      token,
    }).then(response => {
      if (response.error === 'jwt expired') {
        return errorhandler(response);
      }
      return response;
    });
  } catch (err) {
    return errorhandler(err);
  }
}

// Single Section Creation
async function sectionCreate(data, id, token) {
  try {
    return await SuperFetch.post(`/course-section/${id}`, {
      data,
      token,
    }).then(response => {
      if (response.error === 'jwt expired') {
        return errorhandler(response);
      }
      return response;
    });
  } catch (err) {
    return errorhandler(err);
  }
}

async function subSectionCreate(data, id, sID, token) {
  try {
    return await SuperFetch.post(`/course-subsection/${id}/${sID}`, {
      data,
      token,
    }).then(response => {
      if (response.error === 'jwt expired') {
        return errorhandler(response);
      }
      return response;
    });
  } catch (err) {
    return errorhandler(err);
  }
}

// Update api

async function sectionUpdate(data, id, sID, token) {
  try {
    return await SuperFetch.patch(`/course-section/${id}/${sID}`, {
      data,
      token,
    }).then(response => {
      if (response.error === 'jwt expired') {
        return errorhandler(response);
      }
      return response;
    });
  } catch (err) {
    return errorhandler(err);
  }
}

async function sub_SectionUpdate(data, id, sID, subID, token) {
  try {
    return await SuperFetch.patch(`/course-subSection/${id}/${sID}/${subID}`, {
      data,
      token,
    }).then(response => {
      if (response.error === 'jwt expired') {
        return errorhandler(response);
      }
      return response;
    });
  } catch (err) {
    return errorhandler(err);
  }
}

// Delete Api
async function deleteCourse(id, token) {
  try {
    return await SuperFetch.delete(`/course/${id}`, { token }).then(
      response => {
        if (response.error === 'jwt expired') {
          return errorhandler(response);
        }
        return response;
      }
    );
  } catch (err) {
    return errorhandler(err);
  }
}
async function deleteSection(id, sID, token) {
  try {
    return await SuperFetch.delete(`/course-section/${id}/${sID}`, {
      token,
    }).then(response => {
      if (response.error === 'jwt expired') {
        return errorhandler(response);
      }
      return response;
    });
  } catch (err) {
    return errorhandler(err);
  }
}
async function deleteSubSection(id, sID, SubID, token) {
  try {
    return await SuperFetch.delete(`/course-subSection/${id}/${sID}/${SubID}`, {
      token,
    }).then(response => {
      if (response.error === 'jwt expired') {
        return errorhandler(response);
      }
      return response;
    });
  } catch (err) {
    return errorhandler(err);
  }
}

async function getCourses(token, page, pageSize) {
  try {
    return await SuperFetch.get(
      `/all-courses?pageNo=${page}&perPage=${pageSize}`,
      {
        token,
      }
    ).then(response => {
      if (response.error === 'jwt expired') {
        return errorhandler(response);
      }
      return response;
    });
  } catch (err) {
    return errorhandler(err);
  }
}
async function getAuthorCourses(token, page, pageSize) {
  try {
    return await SuperFetch.get(`/courses?pageNo=${page}&perPage=${pageSize}`, {
      token,
    }).then(response => {
      if (response.error === 'jwt expired') {
        return errorhandler(response);
      }
      return response;
    });
  } catch (err) {
    return errorhandler(err);
  }
}
async function getCourse(id, token) {
  try {
    return await SuperFetch.get(`/get-course/${id}`, {
      token,
    }).then(response => {
      if (response.error === 'jwt expired') {
        return errorhandler(response);
      }
      return response;
    });
  } catch (err) {
    return errorhandler(err);
  }
}
async function setAssignCourse(token, courseId, userId) {
  try {
    return await SuperFetch.post(`/assign-course/${userId}/${courseId}`, {
      token,
    }).then(response => {
      if (response.error === 'jwt expired') {
        return errorhandler(response);
      }
      return response;
    });
  } catch (err) {
    return errorhandler(err);
  }
}
async function UnAssignCourse(token, courseId, userId) {
  try {
    return await SuperFetch.delete(`/un-assign-course/${userId}/${courseId}`, {
      token,
    }).then(response => {
      if (response.error === 'jwt expired') {
        return errorhandler(response);
      }
      return response;
    });
  } catch (err) {
    return errorhandler(err);
  }
}
function getFilterCourse(token, arg) {
  return axios({
    method: 'GET',
    url: `${apiUrl}/course-filter/${arg}`,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  }).then(function(response) {
    return response.data;
  });
}
export {
  course,
  sectionsCreate,
  sectionCreate,
  subSectionCreate,
  sectionUpdate,
  sub_SectionUpdate,
  getCourses,
  getCourse,
  deleteCourse,
  deleteSubSection,
  deleteSection,
  courseUpdate,
  getAuthorCourses,
  setAssignCourse,
  getFilterCourse,
  UnAssignCourse,
};
